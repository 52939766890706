<template>
  <div class="home">
    <MyHeader
      @changeFarm="changeFarm"
      :farmName="farmInfo.title"
      :msgList="msgList"
      v-if="!$route.meta.isLogin"
    ></MyHeader>
    <keepAlive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keepAlive>
    <router-view v-else></router-view>
    <!-- 小窗口 -->
    <el-dialog
      class="big-pop"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title=" "
      :visible.sync="mapInfoShow"
      width="727px"
      :before-close="mapInfoClose"
    >
      <div
        class="big-main"
        :style="
          'background: url(' +
          $imgOnlineUrl +
          '/index/popBgimg.png) no-repeat center center; background-size: cover;'
        "
      >
        <div
          class="items"
          v-for="(item, index) in farmList"
          :key="item.id"
          @click="editFarm(item.id, index)"
        >
          <img
            class="imgs"
            :src="item.photo ? uploadImgs + item.photo : defaultImgs"
          />
          <div class="right-info">
            <div class="title">{{ item.title }}</div>
            <div class="address">
              <img
                class="icon11-img"
                :src="$imgOnlineUrl + '/index/icon11.png'"
              />
              <p class="address-txt">
                {{ item.address }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getFarmListApi,
  getMsgApi,
  getMsgListApi,
  getFarmInfoApi,
} from "@/request/api";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    MyHeader: () => import("@/components/MyHeader.vue"),
  },
  data() {
    return {
      msgCount: null,
      msgList: [],
      mapInfoShow: false,
      farmList: [],
      defaultImgs: require("@/assets/image/configure/farm/icon8.png"),
      navIndex: 0,
      total: 20,
      last_page: 1,
      per_page: 10,
      current_page: 1,
      set: null,
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      token: (state) => state.user.token,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    let userToken = localStorage.getItem("darsingToken") || "";
    let exp = +localStorage.getItem("exp") || 0;
    let nowTime = +new Date();
    if (userToken && exp > nowTime) {
      this.getFarmListApi();
      this.getMsgApi();
      this.getMsgListApi();
      this.set = setInterval(() => {
        this.getMsgApi();
        this.getMsgListApi();
      }, 3600000);
    }
  },
  beforeDestroy() {
    if (this.set) {
      clearInterval(this.set);
      this.set = null;
    }
  },
  methods: {
    ...mapMutations("user", ["SET_MSG_COUNT", "SET_FARM_Id", "SET_FARM_INFO"]),

    async getFarmInfoApi(id) {
      const { code, results } = await getFarmInfoApi({
        manor_id: id,
      });
      if (code === 0) {
        let obj = {};
        obj.address = results.address;
        obj.area = results.area;
        obj.title = results.title;
        obj.photo = results.photo;
        obj.position = results.position;
        obj.screen_page = results.screen_page;
        // obj.menu_power = results.menu_power;
        obj.vr_page = results.vr_page;
        obj.name = results.name;
        obj.id = results.id;
        obj.title = results.title;
        obj.zoom = results.zoom;

        this.SET_FARM_INFO({ farmInfo: obj });
        this.SET_FARM_Id({ farmId: results.id });
      }
    },
    async getMsgApi() {
      const { code, results } = await getMsgApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.SET_MSG_COUNT({ msgCount: results.count });
      }
    },
    async getMsgListApi() {
      const { code, results } = await getMsgListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        results.data = results.data.filter((item) => {
          return item.state == 0;
        });
        if (results.data.length > 5) {
          this.msgList = results.data.slice(0, 5);
        } else {
          this.msgList = results.data;
        }
      }
    },
    async getFarmListApi() {
      const { code, results } = await getFarmListApi();
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.farmList = results.data;
      }
    },
    editFarm(id) {
      this.$confirm("确认切换农场?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.mapInfoShow = false;
          this.$notify({
            title: "成功",
            message: "已切换",
            type: "success",
          });
          this.getFarmInfoApi(id);
          this.$router.go(0);
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    changeFarm() {
      this.getFarmListApi();
      this.mapInfoShow = !this.mapInfoShow;
    },
    mapInfoClose() {
      this.mapInfoShow = false;
    },
  },
};
</script>

<style scoped lang="less">
.home {
  min-width: 1600px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.8s;
  }

  .big-pop {
    /deep/.el-dialog {
      background-color: inherit;
      width: 727px !important;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }

    .big-main {
      width: 727px;
      height: 526px;

      padding: 65px 55px 10px 55px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .items {
        width: 100%;
        height: 120px;
        background-color: rgba(62, 144, 229, 0.25);
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
          background-color: rgba(62, 144, 229, 0.15);
        }

        .imgs {
          width: 110px;
          height: 77px;
          margin-right: 17px;
        }

        .right-info {
          width: 100%;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 18px;
            margin-bottom: 24px;
            font-weight: bold;
            color: #fff;
          }

          .address {
            display: flex;
            align-items: center;
            width: 100%;

            .icon11-img {
              width: 10px;
              height: 12px;
              margin-right: 6px;
            }

            .address-txt {
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
